import "./Dashboard.scss";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Dashboard = () => {
  const [authorizeFlowSecond, setAuthorizeFlowSecond] = useState(false);
  const [show, setShow] = useState(true);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(true);
  const [document, setDocument] = useState("");
  const [userPIData, setUserData] = useState("");
  const [metaData, setMetaData] = useState("");
  const [display, setDisplay] = useState(false);
  const [errorConsent, setErrorConsent] = useState(false);
  const [timeOutConsent, setTimeOutConsent] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {

    setError(true);
    setDocument("");
    if (display) {
      const timer = setTimeout(() => {
        setDisplay(false);
      }, 20000);

      return () => clearTimeout(timer);
    }
  }, [display]);

  const handleClose = () => {
    setShow(false);
    setEmail("");
    setAuthorizeFlowSecond(false);
  };

  const onDisplayHide = () => {
    setDisplay(false);
  };

  const onErrorHide = () => {
    setErrorConsent(false);
  };

  const onTimeoutHide = () => {
    setTimeOutConsent(false);
  };

  const handleSubmit = (e, email, document) => {
    e.preventDefault();
    setShowSpinner(true);

    const emailId = email;
    let idType;
    if (document === "passport") {
      idType = "Passport";
    } else {
      idType = "License";
    }
    if (emailId && idType) {
      fetch(
        "https://testfrk.aegis-frk.com/pingone-service/pingConsent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idType: idType,
            email: emailId,
            vendor: "Rent A Car",
          }),
        },
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (
            data.data.StatusCode === "200" &&
            data.data.decision === "allow"
          ) {
            if (data.data.idUserdata) {
              const keyValuePairsuserData = data.data.idUserdata
                .slice(1, -1)
                .split(",");

              const userData = {};
              keyValuePairsuserData.forEach((pair) => {
                const [key, value] = pair.split("=");
                userData[key] = value;
              });

              setUserData(userData);
            } else{
              setUserData('');
            }
            const keyValuePairsIDdata = data.data.idMetadata
              .slice(1, -1)
              .split(",");

            const idData = {};
            keyValuePairsIDdata.forEach((pair) => {
              const [key, value] = pair.split("=");
              idData[key] = value;
            });

            setMetaData(idData);

            setShow(false);
            setAuthorizeFlowSecond(false);
            setShowSpinner(false);
            setDisplay(true);
          } else if (data.data.decision === "denied") {
            console.log("denied");
            setShow(false);
            setAuthorizeFlowSecond(false);
            setShowSpinner(false);
            setErrorConsent(true);
          } else if (data.status === "500" || data.status === "502") {
            setShow(false);
            setAuthorizeFlowSecond(false);
            setShowSpinner(false);
            setTimeOutConsent(true);
          }else {
            console.log("unknown");
            setShow(false);
            setAuthorizeFlowSecond(false);
            setShowSpinner(false);
            setTimeOutConsent(true);
          }
        });
    }
  };

  const onClick = (e) => {
    e.preventDefault();
    setShow(true);
    setAuthorizeFlowSecond(true);
  };

  const isEmailInvalid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setError(true);
    } else {
      setError(false);
    }
    return emailRegex.test(email);
  };

  return (
    <>
      <header className="header-outer">
        <div className="header-inner responsive-wrapper">
          <div
            className="header-logo"
            style={{
              fontSize: "40px",
            }}
          >
            <b>CAR</b> : Rent A Car
          </div>
          <nav className="header-navigation">
            <a href="#">Home</a>
            <a href="#">About</a>
            <a href="#">Blog</a>
            <a href="#">Contact Us</a>
            <button>Menu</button>
          </nav>
        </div>
      </header>
      {authorizeFlowSecond && (
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Verification via AEGIS Platform</Modal.Title>
            </Modal.Header>
            {!showSpinner && (
              <>
                <Modal.Body>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="name@example.com"
                        autoFocus
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        onBlur={(e) => {
                          isEmailInvalid(email);
                        }}
                        // isInvalid={!isEmailInvalid(email)}
                      />
                      {!error && email && (
                        <div>
                          <p>Please write a valid email.</p>
                        </div>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Document Type</Form.Label>
                      <Form.Select
                        value={document}
                        onChange={(e) => setDocument(e.target.value)}
                      >
                        <option value="" disabled selected>
                          Select One
                        </option>
                        <option value="drivingLicense">Driving License</option>
                        <option
                          value="passport"
                          onSelect={(e) => setDocument(e.target.value)}
                        >
                          Passport
                        </option>
                      </Form.Select>
                    </Form.Group>
                  </Form>
                </Modal.Body>
              </>
            )}
            {showSpinner && (
              <Spinner
                animation="border mt-5 mb-5"
                role="status"
                style={{ alignSelf: "center" }}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={(e) => handleSubmit(e, email, document)}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {display && (
        <>
          <Modal
            show={display}
            onHide={onDisplayHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                AEGIS Verified Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Object.entries(userPIData).map(([key, value]) => (
                <div key={key}>
                  <b style={{ marginRight: "5px" }}>{key} </b>  :  {value}
                  <br />
                </div>
              ))}
              {Object.entries(metaData).map(([key, value]) => (
                <div key={key}>
                  <b style={{ marginRight: "5px" }}>{key} </b>  :  {value}
                  <br />
                </div>
              ))}

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onDisplayHide}>Close</Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {errorConsent && (
        <>
          <Modal
            show={errorConsent}
            onHide={onErrorHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Verification Failed
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>User Declined Access Request</p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onErrorHide}>Close</Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {timeOutConsent && (
        <>
          <Modal
            show={timeOutConsent}
            onHide={onTimeoutHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Session Timed Out
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Please try again</p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onTimeoutHide}>Close</Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      <div className="center">
        <section className="container">
          <div className="card">
            <div className="image">
              <img
                src="https://imgd.aeplcdn.com/664x374/cw/ec/23766/Ford-Mustang-Exterior-126883.jpg?wm=0&q=80"
                alt=""
              />
            </div>
            <h2>Ford Mustang</h2>
            <p>Adrenaline chasers: This is your wake-up call.</p>
            <p>
              Starting from <b>120$</b> a Day.<p>Verify Your ID to Book Now</p>
            </p>
          </div>
          <div className="card">
            <div className="image">
              <img
                src="https://imgd.aeplcdn.com/664x374/cw/ec/39232/Porsche-992-911-Right-Front-Three-Quarter-154380.jpg?wm=0&q=80"
                alt=""
              />
            </div>
            <h2>Porsche 911 Carrera S</h2>
            <p>
              The harmony of tradition and modernity–the iconic flyline and the
              continuous light strip.
            </p>
            <p>
              Starting from <b>150$</b> a Day.<p>Verify Your ID to Book Now</p>
            </p>
          </div>
          <div className="card">
            <div className="image">
              <img
                src="https://stimg.cardekho.com/images/carexteriorimages/630x420/Cadillac/Cadillac-Escalade/3027/1560320021838/front-left-side-47.jpg?tr=w-664"
                alt=""
              />
            </div>
            <h2>Cadillac Escalade</h2>
            <p>
              Exciting, bold, iconic—move through the world in a vehicle
              befitting your status.
              <p>
                Starting from <b>150$</b>a Day.<p>Verify Your ID to Book Now</p>
              </p>
            </p>
          </div>
          <div className="card">
            <div className="image">
              <img
                src="https://imgd.aeplcdn.com/664x374/n/cw/ec/108971/mc20-exterior-right-front-three-quarter-2.jpeg?isig=0&q=80"
                alt=""
              />
            </div>
            <h2>Maserati MC20</h2>
            <p>You know what I am.</p>
            <p>
              Starting from <b>250$</b> a Day.<p>Verify Your ID to Book Now</p>
            </p>
          </div>
        </section>

        <a href="https://auth.pingone.com/916fd1e1-7806-45f1-9663-bbe43dd7e5aa/as/authorize?response_type=code&client_id=b619e517-c9e6-46d8-b438-d5579386ce45&scope=openid&redirect_uri=https://cloudfront.aegis-frk.com/">
          <button className="bn632-hover bn26">Verify via Ping Wallet</button>
        </a>

        <a href="https://aegisdev.aegis-frk.com/am/XUI/?realm=/&authIndexType=service&authIndexValue=CreateSession#/">
          <button className="bn632-hover bn25" onClick={(e) => onClick(e)}>
            Verify via AEGIS Platform
          </button>
        </a>
      </div>
    </>
  );
};

export default Dashboard;
